import { PROCESSING_DESTINATION, UPDATE_DESTINATION } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case PROCESSING_DESTINATION: {
      return state.map((destination) => {
        if (destination.id === action.payload) {
          return { ...destination, status: 'processing' };
        }
        return destination;
      });
    }
    case UPDATE_DESTINATION: {
      return state.map((destination) => {
        if (destination.id === action.payload.id) {
          return { ...action.payload };
        }
        return destination;
      });
    }
    default:
      return state;
  }
}
