import { ADD_TAG, DELETE_TAG, UPDATE_TAG } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case ADD_TAG: {
      const tags = state.slice();
      if (!tags.find((tag) => tag.id === action.payload.id)) {
        tags.push(action.payload);
      }
      return tags;
    }
    case DELETE_TAG: {
      const tags = state.slice().filter((tag) => tag.id !== action.payload.id);
      return tags;
    }
    case UPDATE_TAG: {
      let tags = state.slice();
      tags = tags.map((tag) => {
        if (tag.id === action.payload.id) {
          return action.payload;
        }
        return tag;
      });
      return tags;
    }
    default:
      return state;
  }
}
