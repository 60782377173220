import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  MOVE_CATEGORY,
  SAVE_CATEGORY_ORDER,
  UPDATE_CATEGORY,
} from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case ADD_CATEGORY: {
      const categories = state.slice();
      if (!categories.find((cat) => cat.id === action.payload.id)) {
        categories.push(action.payload);
      }
      return categories;
    }
    case DELETE_CATEGORY: {
      const categories = state.slice().filter((category) => category.id !== action.payload.id);
      return categories;
    }
    case MOVE_CATEGORY: {
      const dragId = action.payload.dragIndex;
      const dropId = action.payload.hoverIndex;

      const temp = state.slice();
      const dragIndex = temp.findIndex((category) => category.id === dragId);
      const dragTarget = temp.find((category) => category.id === dragId);
      const dropIndex = temp.findIndex((category) => category.id === dropId);

      temp.splice(dragIndex, 1);
      temp.splice(dropIndex, 0, dragTarget);

      return temp;
    }
    case SAVE_CATEGORY_ORDER: {
      return action.payload;
    }
    case UPDATE_CATEGORY: {
      let categories = state.slice();
      categories = categories.map((category) => {
        if (category.id === action.payload.id) {
          return action.payload;
        }
        return category;
      });
      return categories;
    }
    default:
      return state;
  }
}
