import {
  FETCH_STORIES_BY_DATE,
  DELETE_STORY,
  FETCH_STORY_PUBLISH_STATUS,
  FETCH_STORIES,
  PREFETCH_STORIES,
  PROCESSING_FETCH_STORIES,
  GET_STORY,
} from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_STORY: {
      const storiesData = state.data ? state.data.slice() : [];
      storiesData.push(action.payload);
      return {
        ...state,
        data: storiesData,
      };
    }

    case FETCH_STORIES_BY_DATE: {
      const storiesData = state.data ? state.data.slice() : [];
      const newStories = action.payload.data.filter(
        (story) => storiesData.findIndex((item) => story.id === item.id) < 0,
      );
      storiesData.push(...newStories);

      return {
        ...state,
        data: storiesData,
      };
    }
    case FETCH_STORIES: {
      const newState = action.payload;
      return { ...newState };
    }

    case PREFETCH_STORIES: {
      return {
        ...state,
        meta: {
          ...state.meta,
          nonce: action.payload,
        },
      };
    }

    case PROCESSING_FETCH_STORIES: {
      return {
        ...state,
        meta: {
          ...state.meta,
          processing: true,
        },
      };
    }

    case DELETE_STORY: {
      const meta = { ...state.meta.pagination };
      const storiesData = state.data ? state.data.slice() : [];
      const paginationTransformed = {
        ...meta,
        total: meta.total - 1,
        total_pages: Math.ceil((meta.total - 1) / meta.per_page),
      };
      // Remove initial story
      const storiesTransformed = storiesData.filter(
        (dataStory) => dataStory.id !== action.payload.id,
      );
      // Find translations of the initial story
      const translations = storiesTransformed.map((story) => story.translations);
      const translationsTransformed = translations.map((translation) =>
        translation.filter((t) => t.id !== action.payload.id),
      );
      // Update translations
      translationsTransformed.forEach((translation, idx) => {
        storiesTransformed[idx].translations = [...translation];
      });

      return {
        ...state,
        data: [...storiesTransformed],
        meta: { pagination: { ...paginationTransformed } },
      };
    }

    case FETCH_STORY_PUBLISH_STATUS: {
      const data = state.data.map((story) => {
        const newStory = story;
        let storyDestinations = newStory.destinations;
        action.payload.forEach((status) => {
          if (newStory.id === status.story_id) {
            storyDestinations = storyDestinations.map((storyDestination) => {
              if (storyDestination.id === status.id) {
                return {
                  ...storyDestination,
                  status: status.status,
                  message: status.message,
                  help_url: status.help_url,
                  response: status.response,
                  url: status.url,
                };
              }
              return storyDestination;
            });
          }
        });
        return {
          ...newStory,
          destinations: storyDestinations,
        };
      });
      return {
        ...state,
        data,
      };
    }
    default:
      return state;
  }
}
