export const ADD_TAG = 'addTag';
export const DELETE_TAG = 'deleteTag';
export const UPDATE_TAG = 'updateTag';

export const ADD_CATEGORY = 'addCategory';
export const DELETE_CATEGORY = 'deleteCategory';
export const MOVE_CATEGORY = 'moveCategory';
export const SAVE_CATEGORY_ORDER = 'saveCategoryOrder';
export const UPDATE_CATEGORY = 'updateCategory';

export const ADD_KEYWORD = 'addKeyword';
export const DELETE_KEYWORD = 'deleteKeyword';
